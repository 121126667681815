<template>
  <div v-if="user" class="flex-grow-1">
    <admin-alert 
      v-if="!Object.keys($store.getters['schools/userSchool'](user.userid)).length && $store.state.schools.status.gettingSchool"
    >
      <template v-slot:title>Welcome!</template>
      <template v-slot:content>To maximise your job opportunities click here to expand on your teacher profile and preferences.</template>
    </admin-alert>

    <custom-alert v-if="$store.getters['jobs/lapsedJobs'](false).length">
      You have {{ $store.getters['jobs/lapsedJobs'](false).length }} lapsed {{ pluralize('job', $store.getters['jobs/lapsedJobs'](false).length) }}. Click <router-link :to="{ name: 'LapsedJobs' }">here</router-link> to update listings.
    </custom-alert>

    <div>Dashboard Overview</div>
    <h4 class="font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">
      Welcome <span v-if="user">{{ user.firstName || user.fullName }}</span>
    </h4>

    <v-divider class="my-5"></v-divider>

    <v-row :dense="$vuetify.breakpoint.xs">
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ applicants.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                JOB APPLICATIONS
              </div>
              <router-link
                :to="{ name: 'MyJobs' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Review applications</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ messages.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                NEW MESSAGES
              </div>
              <router-link
                :to="{ name: 'Message' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Reply to messages</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ actives.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                JOBS VACANT
              </div>
              <router-link
                :to="{ name: 'MyJobs' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Award jobs to teachers</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              0
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                NEW ADMIN NOTICES
              </div>
              <div>No new notifications</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-7">
      <h5 class="text-h6 mb-5 font-weight-black">My Active Jobs</h5>

      <preloader v-if="status.getting" />

      <custom-alert v-if="!status.getting && !actives.length" class="mb-5" border="left" text>
        You currently don’t have any active job listings. <br>
        To create a new contract ad, click <router-link :to="{ name: 'Job' }">here</router-link>.
      </custom-alert>

      <div v-if="!status.getting && actives.length">
        <job-card
          v-for="job in actives"
          :key="job.id"
          :job="job"
        />
      </div>

      <v-btn :to="{ name: 'MyJobs' }" color="accent gradient">View All My Jobs</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import JobCard from '@/views/job/JobCard'
import pluralize from 'pluralize'
import 'firebase/auth'

export default {
  metaInfo: {
    title: 'Dashboard'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pluralize,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.jobs.status,
      applicants: state => state.apply.applicants,
      alerts: state => state.notifications.alerts
    }),

    actives: function () {
      return this.$store.getters['jobs/activeJobs'](false)
    },

    messages: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'message'
      })
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    JobCard
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("jobs", ["getCreatedJobs"]),
    ...mapActions("apply", ['allApplicants', 'getApplicants']),
  },

  /*------------------------------------------------------------------------------
   * mounted
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getCreatedJobs()
    this.allApplicants()
    this.getApplicants()

    if (this.user) this.$store.dispatch('schools/getSchoolByUid', this.user.userid)
  },
}
</script>