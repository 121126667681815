<template>
  <div>
    <Employer v-if="user && user.role == 'employer'" />
    <Teacher v-if="user && user.role == 'jobseeker'" />
    <Admin v-if="user && user.role == 'admin'" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Employer from './Employer'
import Teacher from './Teacher'
import Admin from './Admin'

export default {
  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Employer,
    Teacher,
    Admin
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  }
}
</script>