<template>
  <div v-if="user" class="flex-grow-1">
    <div>Dashboard Overview</div>
    <h4 class="font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">
      Welcome <span v-if="user">{{ user.firstName || user.fullName }}</span>
    </h4>

    <v-divider class="my-5"></v-divider>

    <v-row :dense="$vuetify.breakpoint.xs">
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ employers.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                EMPLOYERS
              </div>
              <router-link
                :to="{ name: 'Users' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">View all users</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ teachers.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                TEACHERS
              </div>
              <router-link
                :to="{ name: 'Users' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">View all users</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ actives.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                JOB ADS
              </div>
              <router-link
                :to="{ name: 'AllJobs' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">View all job ads</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ $store.getters['apply/bookings'].length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                BOOKINGS
              </div>
              <router-link
                :to="{ name: 'AllBookedJobs' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">View all job ads</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-7">
      <h5 class="text-h6 mb-5 font-weight-black">Users By State</h5>
      
      <v-card class="mb-5 shadow-lg">
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th>State</th>
                <th class="text-center" width="20%">Teacher</th>
                <th class="text-center" width="20%">Employer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(state, i) in states" :key="i">
                <td>{{ state.long }}</td>
                <td class="text-center">
                  {{ countByState(state.short) }}
                </td>
                <td class="text-center">
                  {{ countEmployerByState(state.short) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <h5 class="text-h6 mb-5 font-weight-black">Employers By Subscription Level</h5>

      <v-card class="shadow-lg">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th class="text-center" width="20%">Active</th>
                <th class="text-center" width="20%">Inactive</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="price in orderBy(packages, (price) => parseInt(price.metadata.order), 'asc')" :key="price.id">
                <td class="text-capitalize">
                  {{ price.description }}
                </td>
                <td class="text-center">
                  {{ getSubscriberCount(price) }}
                </td>
                <td class="text-center">
                  {{ getInactiveSubscriberCount(price) }}
                </td>
              </tr>
              <tr>
                <td>Trial</td>
                <td class="text-center">
                  {{ $store.getters['subscription/onFreeTrial'].length }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import 'firebase/auth'
import orderBy from 'lodash/orderBy'

export default {
  metaInfo: {
    title: 'Dashboard Overview'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      states: [
        { long: 'Australian Capital Territory', short: 'ACT' },
        { long: 'New South Wales', short: 'NSW' },
        { long: 'Northern Territory', short: 'NT' },
        { long: 'Queensland', short: 'QLD' },
        { long: 'South Australia', short: 'SA' },
        { long: 'Tasmania', short: 'TAS' },
        { long: 'Victoria', short: 'VIC' },
        { long: 'Western Australia', short: 'WA' },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      users: state => state.users.users,
      jobs: state => state.jobs.jobs,
      status: state => state.jobs.status,
      applicants: state => state.apply.applicants,
      packages: state => state.subscription.packages,
      subscriptions: state => state.subscription.subscriptions,
    }),

    ...mapGetters('subscription', [
      'getSubscriberCount',
      'getInactiveSubscriberCount'
    ]),

    actives: function () {
      return this.jobs.filter(job => {
        return job.status !== 'Closed'
      })
    },

    messages: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'message'
      })
    },

    employers: function () {
      return this.users.filter(user => user.role == 'employer')
    },
    
    teachers: function () {
      return this.users.filter(user => user.role == 'jobseeker')
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("jobs", ["getCreatedJobs", 'getAllJobs']),
    ...mapActions("apply", ['allApplicants', 'getApplicants']),
    ...mapActions("users", ["getUsers"]),
    ...mapActions('subscription', ['getCustomers', 'getPackages']),

    countByState(state) {
      let users = this.users

      users = users.filter(user => {
        return user.role == 'jobseeker'
      })
    
      users = users.filter(user => {
        return user.address && user.address.administrative_area_level_1 == state
      })

      return users.length
    },
    
    countEmployerByState(state) {
      let users = this.users
    
      users = users.filter(user => {
        return user.role == 'employer'
      })

      users = users.filter(user => {
        let school = this.$store.getters['schools/userSchool'](user.userid)
        if (school) return school.address && school.address.administrative_area_level_1 == state
        else return false
      })

      return users.length
    },
  },

  /*------------------------------------------------------------------------------
   * mounted
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getUsers()
    this.getAllJobs()
    this.allApplicants()
    this.getCustomers()
    this.getPackages()

    if (this.user) this.$store.dispatch('schools/getSchoolByUid', this.user.userid)
  },
}
</script>