<template>
  <div v-if="user" class="flex-grow-1">
    <admin-alert v-if="!user.address && !profile.phone">
      <template v-slot:title>‘Welcome!</template>
      <template v-slot:content>To maximise your job opportunities click <router-link :to="{ name: 'MyProfile' }" class="white--text">here</router-link> to expand on your teacher profile and preferences.</template>
    </admin-alert>

    <div>Dashboard Overview</div>
    <h4 class="text-h3 font-weight-black">
      Welcome <span v-if="user">{{ user.firstName || user.fullName }}</span>
    </h4>

    <v-divider class="my-5"></v-divider>

    <v-row :dense="$vuetify.breakpoint.xs">
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ openJobs.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                JOB APPLICATIONS
              </div>
              <router-link
                :to="{ name: 'Applied' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Review job ads</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ messages.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                NEW MESSAGES
              </div>
              <router-link
                :to="{ name: 'Message' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Reply to messages</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              {{ invites.length }}
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                JOB INVITATIONS
              </div>
              <router-link
                :to="{ name: 'Invitations' }"
                class="text-decoration-none"
              >
                <span class="underline-on-hover">Review new job invitations</span>
                <v-icon color="primary" small>mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="shadow-md" rounded="lg">
          <v-card-text class="d-flex align-center pa-5">
            <div
              class="accent--text font-weight-bold mr-5"
              :style="{ fontSize: '50px' }"
            >
              0
            </div>
            <div>
              <div
                class="subtitle-1 primary--text heading-font font-weight-bold"
              >
                NEW ADMIN NOTICES
              </div>
              <div>No new notifications</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-7">
      <h5 class="text-h6 mb-5 font-weight-black">Applications In Progress</h5>

      <preloader v-if="status.getting" />

      <custom-alert v-if="!status.getting && !ordered.length">
        You currently have no active job applications.
      </custom-alert>

      <div v-if="!status.getting && actives.length">
        <JobsCard 
          v-for="job in ordered"
          :key="job.id"
          :job="job"
          applied
        />
      </div>

      <v-btn :to="{ name: 'Jobs' }" color="accent gradient">View All My Jobs</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import JobsCard from '@/views/job/JobCard'
import moment from 'moment'

export default {
  components: {
    JobsCard,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      profile: state => state.profile.profile,
      invites: state => state.invite.myInvites,
      jobs: state => state.apply.applies,
      status: state => state.apply.status,
      jobApplied: state => state.apply.applied,
      alerts: state => state.notifications.alerts
    }),

    actives: function () {
      return this.jobs.filter((job) => {
        return job.status == "Open"
      })
    },

    messages: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'message'
      })
    },

    ordered: function () {
      let jobs = this.jobs.filter(job => {
        return this.$startDate(job.contractDates) > moment().valueOf()
      })

      jobs = jobs.filter(job => job.status == 'Open')

      return _orderBy(jobs, 'createdAt', this.orderBy)
    },

    openJobs: function () {
      let jobs = this.jobs.filter(job => {
        return this.$startDate(job.contractDates) > moment().valueOf()
      })

      return jobs.filter(job => job.status == 'Open')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('apply', ['getApplies', 'getJobApplied']),
    ...mapActions('invite', ['getInvites',]),

    getJobStatus(job) {
      let data = []
      if (this.jobApplied) {
        data = _find(this.jobApplied, function (applied) {
          return applied.jobId == job.id
        })
      }
      return data.status
    },
  },

  /*------------------------------------------------------------------------------
   * mounted
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getApplies()
    this.getJobApplied()
    this.getInvites()
  },
}
</script>